<template>
  <div class="notification-type-list">
    <h1>Notification Types</h1>
    <div v-if="isDataLoading" class="loading-overlay">
      <div class="loading-indicator">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div class="language-switcher">
      <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
      <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
    </div>

    <MasterFilter
        :filters="filters"
        :filtersDisplayed="true"
        @apply-filters="applyFilters"
        @reset-filters="resetFilters"
    />

    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="[]"
      page="NotificationTypes"
      @update-table="getNotificationTypesHandler"
      @show-content-data="handleShowContent"
    />

    <NotificationPreview
      v-if="showPopup"
      :notificationTypeId="notificationTypeId"
      :notificationData="notificationData"
      @close="closePopup()"
    />

  </div>
</template>

<script>
import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import notificationMixin from "@/mixins/notification";
import NotificationPreview from "./popups/NotificationPreview";
import MasterFilter from "@/components/shared/filters/MasterFilter.vue";

export default {
  name: "NotificationsTypes",
  mixins: [notificationMixin],
  components: {
    MasterFilter,
    DataTableHolder,
    NotificationPreview,
  },
  data() {
    return {
      notificationTypeId: null,
      notificationData: null,
      notificationTypes: null,
      isDataLoading: false,
      columns: [
        {
          title: "ID",
          name: "id",
          sortable: false,
        },
        {
          title: "Text",
          name: "notificationText",
          sortable: false,
        },
        {
          title: "Trigger",
          name: "description",
          sortable: false,
        },
        {
          title: "Receivers",
          name: "receivers",
          sortable: false,
        },
        {
          title: "Category",
          name: "category",
          sortable: false,
        },
        {
          title: "isActive",
          name: "status",
          sortable: false,
        },
        {
          title: "Is Push",
          name: "isPush",
          sortable: false,
        },
        {
          title: "Tested",
          name: "isTested",
          sortable: false,
        },
        {
          title: "Content",
          name: "notificationData",
          sortable: false,
        },
      ],
      rows: [],
      originalRows: [],
      showPopup: false,
      currentLanguage: 'en',
      filters: {
        dropdowns: {
          category: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 0, name: 'System' },
              { id: 1, name: 'Portfolio' },
              { id: 2, name: 'Contest' },
              { id: 3, name: 'Transaction' },
              { id: 4, name: 'Permission' },
              { id: 5, name: 'Interaction' },
              { id: 6, name: 'Education' }
            ],
            label: 'name',
            placeholder: "Select Category",
            multiselect: true,
            tableName: 'category'
          }
        }
      },
      filtersDisplayed: true,
      activeFilterIndex: -1,
      filtersToSend: {},
    };
  },
  methods: {
    switchLanguage(language) {
      this.currentLanguage = language;

      // Update the notificationText property for each row in this.rows
      for (let i = 0; i < this.rows.length; i++) {
        const notificationType = this.rows[i];
        notificationType.notificationText = this.constructTextFromContentData(notificationType.notificationData.contentData);
      }
    },
    getNotificationTypesHandler() {
      this.getNotificationsTypes().then((res) => {
        const notificationTypes = res.notificationTypes;
        this.originalRows = [];
        for (const notificationType of notificationTypes) {
          if (notificationType.isEmail) {
            continue;
          }

          let row = {
            id: notificationType.id,
            notificationText: this.constructTextFromContentData(notificationType.contentData),
            description: notificationType.description,
            receivers: notificationType.receivers,
            category: this.mapIdToCategoryName(notificationType.category),
            status: notificationType.status,
            isPush: notificationType.isPush,
            isTested: notificationType.isTested,
            notificationData: notificationType,
          };

          this.originalRows.push(row);
        }
        this.applyFilters();
      })
      .finally(() => {
        this.isDataLoading = false;
      });
    },
    mapIdToCategoryName(id) {
      const category = this.filters.dropdowns.category.dropdownOptions.find(option => option.id === id);
      return category.name;
    },
    constructTextFromContentData(contentData) {
      if (!contentData || !contentData[this.currentLanguage]) {
        return '';
      }
      let additionalClass = contentData.status ? ' ' + contentData.status : '';

      const items = contentData[this.currentLanguage]?.items || [];
      let text = '';
      for (const item of items) {
        if (item.type === 'object') {
          text += '{{Object}}'
        } else if (item.type === 'link' || item.type === 'url') {
          if ((item.type === 'url' && !item.title) || (item.type === 'link' && !item.title)) {
            continue;
          }

          text += `
          <div class="route-tooltip">
            <a class="link">${item.title}</a>
            <div class="real-route">${this.getRealRoute(item)}</div>
          </div>`;
        } else if (item.type === 'text') {
          text += ' ' + item.text + ' ';
        }
      }

      const result = text.trim();
      if (!result || result.length === 0) {
        return '';
      }

      return `<span class="notification-text-column${additionalClass}">${result}</span>`;
    },
    getRealRoute(item) {
      if (item.type && item.type === 'url') {
        return item.link;
      }

      const siteUrl = 'https://umushroom.com/' + this.currentLanguage + '/';
      return siteUrl + item.route;
    },
    determineNotificationType(type) {
      if (type.isEmail) return "Email";
      if (type.isWebHeader) return "Web Header";
      if (type.isWebMenu) return "Web Menu";
      return "Unknown";
    },
    handleShowContent(data) {
      this.notificationTypeId = data.id;
      this.notificationData = data.notificationData;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.isDataLoading = true;
      this.getNotificationTypesHandler();
    },
    applyFilters() {
      let dropdowns = this.filters.dropdowns;
      this.filtersToSend = {};

      for (const key in dropdowns) {
        let dropdownSelectedOptions = dropdowns[key].selectedOptions;
        if (dropdownSelectedOptions && dropdownSelectedOptions.length > 0) {
          this.filtersToSend[key] = dropdownSelectedOptions.map(option => option.name);
        }
      }

      // Filter rows based on selected categories
      this.rows = this.originalRows.filter(row => {
        if (this.filtersToSend.category && this.filtersToSend.category.length > 0) {
          return this.filtersToSend.category.includes(row.category);
        }
        return true;
      });

      this.updateResults = !this.updateResults;
    },
    resetFilters() {
      for (const key in this.filters.dropdowns) {
        this.filters.dropdowns[key].selectedOptions = [];
      }
      this.filtersToSend = {};
      this.rows = [...this.originalRows];
      this.updateResults = !this.updateResults;
    },
  },
};

</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.language-switcher {
  display: flex;
  justify-content: flex-end;
}
  
.language-switcher button {
  border: none;
  padding: 9px 15px;
  font-weight: 600;
  cursor: pointer;
}
  
.language-switcher button.active {
  background-color: #3c6e71;
  color: white;
}
</style>

<style>
.notification-text-column {
  display: block;
  padding: 10px 5px 10px 10px;
}

.notification-text-column .route-tooltip {
  position: relative;
  display: inline-block;
  color: black;
}

.notification-text-column .route-tooltip .link {
  width: 100%;
}

.notification-text-column .route-tooltip:hover .real-route {
  display: block;
  z-index: 2;
}

.notification-text-column .route-tooltip:hover .link {
  text-decoration: none;
}

.notification-text-column .route-tooltip .real-route {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.notification-text-column .route-tooltip .link {
  text-decoration: underline;
  color: var(--dark-green);
  cursor: pointer;
}

.notification-text-column.success {
  background: #3DD598;
}

.notification-text-column.warning {
  background: #B9961A;
}

.notification-text-column.error {
  background: #DE3A3A;
}

.notification-text-column.success, .notification-text-column.warning, .notification-text-column.error {
  color: white;
}

.notification-text-column.success div > a, .notification-text-column.warning div > a, .notification-text-column.error div > a {
  color: white !important;
}
</style>

import Dashboard from '../components/dashboard/Dashboard'

import Login from '../components/login/Login'
import VerifyLogin from '../components/login/VerifyLogin'
import AccountSettings from '../components/user/AccountSettings'

// * Marketing
import UsersMarketingCharts from '../components/marketing/UsersMarketingCharts'
import UsersMarketing from '../components/marketing/UsersMarketing'
import WealthManagersMarketing from '../components/marketing/WealthManagersMarketing'
import AssetManagersMarketing from '../components/marketing/AssetManagersMarketing'
import ContestUsers from "@/components/marketing/ContestUsers.vue";
import QuizList from '../components/quiz/QuizList'
import QuizQuestions from '../components/quiz/QuizQuestions'
import QuizParticipants from '../components/quiz/QuizParticipants'
import QuizSummary from '../components/quiz/QuizSummary'
import BannersList from '../components/banners/BannersList'
import ScheduledNotifications from '../components/marketing/ScheduledNotifications'
import SystemAchievements from "../components/system/SystemAchievements";
import FilterUsers from "@/components/marketing/FilterUsers.vue";
import UsersUTM from "@/components/marketing/UsersUTM.vue";

// * Users
import Users from '../components/users/Users'
import Subscriptions from '../components/users/Subscriptions'
import Plans from '../components/users/Plans'
import WealthManagersNew from '../components/users/WealthManagersNew.vue'
import CommunityGroups from "@/components/users/CommunityGroups.vue";
import Brokers from '../components/users/Brokers'
import InstrumentRights from '../components/users/InstrumentRights'
import UsersAchievements from "../components/users/UsersAchievements"
import UsersNotifications from "../components/users/UsersNotifications"
import Contests from "@/components/users/Contests.vue";
import Collections from "@/components/users/Collections.vue";

// * System
import CommandsLog from '../components/system/CommandsLog'
import Commands from '../components/system/Commands'
import CommandsSchedule from '../components/system/CommandsSchedule'
import CommandsChartView from '../components/system/CommandsChartView'
import FAQs from '../components/system/FAQs'
import FAQCategories from '../components/system/FAQCategories'
import Glossary from '../components/system/Glossary'
import GlossaryCategories from '../components/system/GlossaryCategories'
import OldUrlNames from "@/components/system/OldUrlNames.vue";
import Tags from "@/components/system/Tags.vue";
import UmuBenchmarks from "@/components/system/UmuBenchmarks.vue";
import UmuBenchmarksByShareClasses from "@/components/system/UmuBenchmarksByShareClasses.vue";
import UmuSocialMediaSentiments from "@/components/system/UmuSocialMediaSentiments.vue";
// * Knowledge
import Knowledge from '../components/knowledge/Knowledge'
import KnowledgeSection from '../components/knowledge/KnowledgeSection'

import KnowledgeSessionEdit from '../components/knowledge/KnowledgeSessionEdit'
// * Equities
import CandidateEquities from '../components/equities/CandidateEquities.vue'
import CandidateEquitiesNew from '../components/equities/CandidateEquitiesNew.vue'
import ActiveEquities from "@/components/equities/ActiveEquities.vue";
import ActiveEquitiesNew from "@/components/equities/ActiveEquitiesNew.vue";
import RetiredEquities from "@/components/equities/RetiredEquities.vue";
import RetiredEquitiesNew from '../components/equities/RetiredEquitiesNew'
import CandidateEquitiesRiskScores from "@/components/equities/CandidateEquitiesRiskScores.vue";
import ActiveEquitiesRiskScores from "@/components/equities/ActiveEquitiesRiskScores.vue";
import CandidateEquitiesReturns from "@/components/equities/CandidateEquitiesReturns.vue";
import ActiveEquitiesReturns from "@/components/equities/ActiveEquitiesReturns.vue";
import Exchanges from '../components/equities/Exchanges';
import StockSplits from "@/components/equities/StockSplits.vue";
import EquitiesFinancialResults from "@/components/equities/EquitiesFinancialResults.vue";
import UmuCompanyDescriptions from "@/components/equities/UmuCompanyDescriptions.vue";
// * Crypto

import CryptoOverview from '../components/crypto/CryptoOverview'
// * Funds
import FundAdvisors from '../components/funds/FundAdvisors'
import FundGroupConsolidations from '../components/funds/FundGroupConsolidations'
import FundUmbrellaManagers from '../components/funds/FundUmbrellaManagers'
import FundAdvisorsMds from '../components/funds/FundAdvisorsMds'
import FundGroupConsolidationsMds from '../components/funds/FundGroupConsolidationsMds'
import InvestmentFundManagersMds from '../components/funds/InvestmentFundManagersMds'
import AssetManagersUrls from '../components/funds/AssetManagersUrls'
import FundUmbrellaFundCategories from '../components/funds/FundUmbrellaFundCategories'
import FundMarketData from '../components/funds/FundMarketData'
import FundUmbrellaFundsCount from '../components/funds/FundUmbrellaFundsCount'
import FundUmbrellaCharacteristics from '../components/funds/FundUmbrellaCharacteristics'
import FundCharacteristics from '../components/funds/FundCharacteristics'
import FundUmbrellaSectors from '../components/funds/FundUmbrellaSectors'
import FundUmbrellaStyles from '../components/funds/FundUmbrellaStyles'
import FundUmbrellaGeographies from '../components/funds/FundUmbrellaGeographies'
import FundBenchmarks from '../components/funds/FundBenchmarks'
import FundBenchmarkMappings from '../components/funds/FundBenchmarkMappings'
import FundSuitabilities from '../components/funds/FundSuitabilities'
import FundsPublicDescriptions from "@/components/funds/FundsPublicDescriptions.vue";
import FundCountries from '../components/funds/FundCountries'

import InstrumentsByTag from "@/components/system/InstrumentsByTag.vue";
// * Shared

import MarketDataMds from "@/components/shared/marketData/MarketDataMds.vue";
// * Portfolios
import PortfolioOrders from '../components/portfolios/PortfolioOrders'
import PortfolioSummary from '../components/portfolios/PortfolioSummary'
import PortfolioPerformance from '../components/portfolios/PortfolioPerformance'
import TransactionsBySecurity from "@/components/portfolios/TransactionsBySecurity.vue";

import SubscribersPayments from "@/components/users/SubscribersPayments.vue";
// * Translations
import Translations from "@/components/translations/Translations.vue";
import NotificationsTypes from "@/components/translations/NotificationsTypes.vue";

// * If user is NOT authenticated redirect to Login page
function authGuard(next) {
  if (!localStorage.getItem('x-auth-token')) {
    next('/login');
  } else {
    next();
  }
}

const routes = [
  // * Main Routes
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: (to, from, next) => {
        // * If user is authenticated and tries to go to login page redirect to Dashboard
        if (localStorage.getItem('x-auth-token')) {
          next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/verify-login/:token',
      name: 'VerifyLogin',
      component: VerifyLogin,
    },
    {
      path: '/account-settings',
      name: 'AccountSettings',
      component: AccountSettings,
    },
  // * Main Routes End
  // * Marketing
    {
      path: '/marketing/users/charts',
      name: 'UsersMarketingCharts',
      component: UsersMarketingCharts,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/marketing/users',
      name: 'UsersMarketing',
      component: UsersMarketing,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/marketing/users/utm',
        name: 'UsersUTM',
        component: UsersUTM,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/marketing/wealth-managers',
      name: 'WealthManagersMarketing',
      component: WealthManagersMarketing,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/marketing/asset-managers',
      name: 'AssetManagersMarketing',
      component: AssetManagersMarketing,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/marketing/contest-users',
      name: 'ContestUsers',
      component: ContestUsers,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/quizzes',
      name: 'QuizList',
      component: QuizList,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/quiz/questions/:id',
      name: 'QuizQuestions',
      component: QuizQuestions,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/quizzes/participants',
      name: 'QuizParticipants',
      component: QuizParticipants,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/quizzes/summary',
      name: 'QuizSummary',
      component: QuizSummary,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/banners',
      name: 'BannersList',
      component: BannersList,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/scheduled-notifications',
      name: 'ScheduledNotifications',
      component: ScheduledNotifications,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
  // * Marketing End
  // * Users
    {
      path: '/users',
      name: 'Users',
      component: Users,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/users/subscriptions',
      name: 'Subscriptions',
      component: Subscriptions,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/users/plans',
        name: 'Plans',
        component: Plans,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/users/wealth-managers-new',
      name: 'WealthManagersNew',
      component: WealthManagersNew,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/users/community-groups',
        name: 'CommunityGroups',
        component: CommunityGroups,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/users/brokers',
      name: 'Brokers',
      component: Brokers,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/users/permissions',
      name: 'InstrumentRights',
      component: InstrumentRights,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/users/subscribers-payments',
        name: 'SubscribersPayments',
        component: SubscribersPayments,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/users/contests',
        name: 'Contests',
        component: Contests,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/users/collections',
        name: 'Collections',
        component: Collections,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
  // * Users End
  // * System
    {
      path: '/system/commands-log',
      name: 'CommandsLog',
      component: CommandsLog,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/commands',
      name: 'Commands',
      component: Commands,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/commands-schedule',
      name: 'CommandsSchedule',
      component: CommandsSchedule,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/commands-chart-view',
      name: 'CommandsChartView',
      component: CommandsChartView,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/faqs',
      name: 'FAQs',
      component: FAQs,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/faq-categories',
      name: 'FAQCategories',
      component: FAQCategories,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/glossary',
      name: 'Glossary',
      component: Glossary,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/system/glossary-categories',
      name: 'GlossaryCategories',
      component: GlossaryCategories,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
  // * System End
  // * Knowledge
    {
      path: '/knowledge',
      name: 'Knowledge',
      component: Knowledge
    },
    {
      path: '/knowledge/:course/:module/:section',
      name: 'KnowledgeSection',
      component: KnowledgeSection
    },
    {
      path: '/knowledge/:course/:module/:section/edit/:session',
      name: 'KnowledgeSessionEdit',
      component: KnowledgeSessionEdit
    },
  // * Knowledge End
  // * Equities
    {
      path: '/equities/candidate',
      name: 'CandidateEquities',
      component: CandidateEquities,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/equities/candidate-new',
        name: 'CandidateEquitiesNew',
        component: CandidateEquitiesNew,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/risk-scores-active',
        name: 'ActiveEquitiesRiskScores',
        component: ActiveEquitiesRiskScores,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/risk-scores-candidate',
        name: 'CandidateEquitiesRiskScores',
        component: CandidateEquitiesRiskScores,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/returns-candidate',
        name: 'CandidateEquitiesReturns',
        component: CandidateEquitiesReturns,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/returns-active',
        name: 'ActiveEquitiesReturns',
        component: ActiveEquitiesReturns,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/equities/active',
      name: 'ActiveEquities',
      component: ActiveEquities,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/equities/active-new',
        name: 'ActiveEquitiesNew',
        component: ActiveEquitiesNew,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/equities/retired',
      name: 'RetiredEquities',
      component: RetiredEquities,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/equities/retired-new',
        name: 'RetiredEquitiesNew',
        component: RetiredEquitiesNew,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/equities/exchanges',
      name: 'Exchanges',
      component: Exchanges,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/equities/market-data-active',
        name: 'ActiveEquitiesMarketData',
        component: MarketDataMds,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/market-data-candidate',
        name: 'CandidateEquitiesMarketData',
        component: MarketDataMds,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/financial-results',
        name: 'EquitiesFinancialResults',
        component: EquitiesFinancialResults,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/umu-company-descriptions',
        name: 'UmuCompanyDescriptions',
        component: UmuCompanyDescriptions,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/funds/public-descriptions',
        name: 'PublicDescriptionsFunds',
        component: FundsPublicDescriptions,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/equities/stock-splits',
        name: 'StockSplits',
        component: StockSplits,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
  // * Equities End
  // * Crypto
    {
      path: '/crypto/overview',
      name: 'CryptoOverview',
      component: CryptoOverview,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
  // * Crypto End
  // * Funds
    {
      path: '/funds/fund-advisors',
      name: 'FundAdvisors',
      component: FundAdvisors,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/group-consolidations',
      name: 'FundGroupConsolidations',
      component: FundGroupConsolidations,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/umbrella-managers',
      name: 'FundUmbrellaManagers',
      component: FundUmbrellaManagers,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/fund-advisors-mds',
      name: 'FundAdvisorsMds',
      component: FundAdvisorsMds,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/group-consolidations-mds',
      name: 'FundGroupConsolidationsMds',
      component: FundGroupConsolidationsMds,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/investment-fund-managers-mds',
      name: 'InvestmentFundManagersMds',
      component: InvestmentFundManagersMds,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/funds/asset-managers',
        name: 'AssetManagersUrls',
        component: AssetManagersUrls,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/funds/umbrella-fund-categories',
      name: 'FundUmbrellaFundCategories',
      component: FundUmbrellaFundCategories,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/market-data',
      name: 'FundMarketData',
      component: FundMarketData,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/market-data-mds-funds',
      name: 'MarketDataMdsFunds',
      component: MarketDataMds,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/market-data-mds-etfs',
      name: 'MarketDataMdsEtfs',
      component: MarketDataMds,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/crypto/market-data',
        name: 'MarketDataMdsCryptos',
        component: MarketDataMds,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
      path: '/funds/umbrella-funds-count',
      name: 'FundUmbrellaFundsCount',
      component: FundUmbrellaFundsCount,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/umbrella-characteristics',
      name: 'FundUmbrellaCharacteristics',
      component: FundUmbrellaCharacteristics,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/:fundType/characteristics',
      name: 'FundCharacteristics',
      component: FundCharacteristics,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/umbrella-sectors',
      name: 'FundUmbrellaSectors',
      component: FundUmbrellaSectors,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/umbrella-styles',
      name: 'FundUmbrellaStyles',
      component: FundUmbrellaStyles,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/umbrella-geographies',
      name: 'FundUmbrellaGeographies',
      component: FundUmbrellaGeographies,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/benchmarks',
      name: 'FundBenchmarks',
      component: FundBenchmarks,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/benchmark/mappings',
      name: 'FundBenchmarkMappings',
      component: FundBenchmarkMappings,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/funds/suitabilities',
      name: 'FundSuitabilities',
      component: FundSuitabilities,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
        path: '/funds/fund-countries',
        name: 'FundCountries',
        component: FundCountries,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
  // * Funds End
  // * Portfolios
    {
      path: '/portfolios/orders',
      name: 'PortfolioOrders',
      component: PortfolioOrders,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/portfolios/summary',
      name: 'PortfolioSummary',
      component: PortfolioSummary,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
    {
      path: '/portfolios/performance',
      name: 'PortfolioPerformance',
      component: PortfolioPerformance,
      beforeEnter: (to, from, next) => {
        authGuard(next);
      }
    },
  // * Portfolios End
  // * Achievements
    {
        path: '/system/achievements',
        name: 'SystemAchievements',
        component: SystemAchievements,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/users/achievements',
        name: 'UsersAchievements',
        component: UsersAchievements,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/users/notifications',
        name: 'UsersNotifications',
        component: UsersNotifications,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
  // * Achievements End
    {
        path: '/marketing/users/filter-by',
        name: 'FilterUsers',
        component: FilterUsers,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/system/old-url-names',
        name: 'OldUrlNames',
        component: OldUrlNames,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/portfolios/transactions-by-security',
        name: 'TransactionBySecurity',
        component: TransactionsBySecurity,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/system/tags',
        name: 'Tags',
        component: Tags,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/system/tags-by-instruments',
        name: 'InstrumentsByTag',
        component: InstrumentsByTag,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/system/umu-benchmarks',
        name: 'UmuBenchmarks',
        component: UmuBenchmarks,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/system/umu-benchmarks/by-share-classes',
        name: 'UmuBenchmarksByShareClasses',
        component: UmuBenchmarksByShareClasses,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/system/umu-social-media-sentiment',
        name: 'UmuSocialMediaSentiments',
        component: UmuSocialMediaSentiments,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/translations/notification-types',
        name: 'NotificationsTypes',
        component: NotificationsTypes,
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/translations/mobile-app',
        name: 'MobileAppTranslation',
        component: Translations,
        props: { translationType: 2 },
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/translations/front-end',
        name: 'FrontEndTranslation',
        component: Translations,
        props: { translationType: 3 },
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
    {
        path: '/translations/back-end',
        name: 'BackEndTranslation',
        component: Translations,
        props: { translationType: 4 },
        beforeEnter: (to, from, next) => {
            authGuard(next);
        }
    },
];

export default routes

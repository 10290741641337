<template>
  <div class="payment-transactions">
    <h1>
      Subscribers' Payment Transactions <small v-if="total">({{ total }})</small>
    </h1>
    <button class="export-btn" @click="exportExcel()">Export in Excel</button>

    <!--    Calendar Filter-->
    <div class="field">
      <label>From :</label>
      <div class="date-filter">
        <Datepicker v-model="fromDate" format="dd.MM.yyyy" :enable-time-picker="false" />
      </div>
    </div>
    <div class="field">
      <label>To :</label>
      <div class="date-filter">
        <Datepicker v-model="toDate" format="dd.MM.yyyy" :enable-time-picker="false" />
      </div>
    </div>

    <!-- Country Filter -->
    <div class="field">
      <label>Country :</label>
      <div class="country-filter">
        <select v-model="countryOfDomicile">
          <option value="">All</option>
          <option value="CH">CH</option>
          <option value="DE">DE</option>
          <option value="AT">AT</option>
          <option value="GB">GB</option>
          <option value="NL">NL</option>
          <option value="ES">ES</option>
        </select>
      </div>
    </div>

    <!-- Country Filter -->
    <div class="field">
      <label>Operation :</label>
      <div class="country-filter">
        <select v-model="operation">
          <option value="">All</option>
          <option value="payment">Payment</option>
          <option value="declined">Declined</option>
          <option value="refunded">Refunded</option>
          <option value="auth-success">Auth-success</option>
          <option value="pre-authorization">Pre-authorization</option>
          <option value="webhook">Webhook</option>
          <option value="expired">Expired</option>
          <option value="cancelled">Cancelled</option>
          <option value="unknown">Unknown</option>
        </select>
      </div>
    </div>

    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :actions="[]"
      :updateResults="updateResults"
      @update-table="getSubscribersPaymentsHandler"
    />
  </div>
</template>

<script>
import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import usersMixin from "@/mixins/users";
import userMixin from "@/mixins/user";
import { utils, writeFile } from "xlsx";

export default {
  name: "SubscribersPayments",
  mixins: [usersMixin, userMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        { title: "Payment Log ID", name: "paymentLogId", sortable: true },
        { title: "Subscription ID", name: "subscriptionId", sortable: true },
        { title: "Date", name: "operationDate", sortable: true },
        { title: "Last Name", name: "lastName", sortable: true },
        { title: "First Name", name: "firstName", sortable: true },
        { title: "Email", name: "email", sortable: false },
        { title: "Paid Plan", name: "paidPlan", sortable: false },
        { title: "Billing Period", name: "billingPeriod", sortable: false },
        { title: "Net Amount", name: "amountExVAT", sortable: false },
        { title: "Gross Amount", name: "amountIncVAT", sortable: false },
        { title: "VAT Amount", name: "amountVAT", sortable: false },
        { title: "VAT Rate", name: "vatRate", sortable: false },
        { title: "Currency", name: "currencyCode", sortable: false },
        { title: "Payrexx Fee", name: "payrexxFee", sortable: false },
        { title: "Card Brand", name: "cardBrand", sortable: false },
        { title: "Card Last Digits", name: "cardNumber", sortable: false },
        { title: "Card Expiry Date", name: "cardExpiryDate", sortable: false },
        { title: "Country of Domicile", name: "countryOfDomicile", sortable: false },
        { title: "Operation Type", name: "operation", sortable: false },
        { title: "Promo Code", name: "promoCode", sortable: false },
      ],
      pages: 0,
      total: null,
      fullData: null,
      fromDate: null,
      toDate: null,
      updateResults: false,
      countryOfDomicile: "",
      operation: "",
    };
  },
  created() {
    this.fromDate = this.startOfMonth();
    this.toDate = this.today();
  },
  watch: {
    fromDate() {
      this.updateResults = !this.updateResults;
    },
    toDate() {
      this.updateResults = !this.updateResults;
    },
    countryOfDomicile() {
      this.updateResults = !this.updateResults;
    },
    operation() {
      this.updateResults = !this.updateResults;
    },
  },

  methods: {
    getSubscribersPaymentsHandler(data) {
      this.setFilters(data);

      this.getSubscribersPayments(data)
        .then((result) => {
          this.pages = result.pagesCount;
          let paymentsFormatted = [];
          for (const payment of result.userRows) {
            let paymentObject = {
              paymentLogId: payment.paymentLogId,
              subscriptionId: payment.subscriptionId,
              operationDate: payment.operationDate,
              lastName: payment.lastName,
              firstName: payment.firstName,
              email: payment.email,
              paidPlan: this.convertToPlan(payment.paidPlan),
              billingPeriod: this.convertToPeriod(payment.billingPeriod),
              amountExVAT: this.formatDecimals(payment.amountExVAT),
              amountIncVAT: this.formatDecimals(payment.amountIncVAT),
              amountVAT: this.formatDecimals(payment.amountVAT),
              vatRate: payment.vatRate,
              currencyCode: payment.currencyCode,
              payrexxFee: payment.payrexxFee,
              cardBrand: payment.cardBrand,
              cardNumber: payment.cardNumber,
              cardExpiryDate: payment.cardExpiryDate,
              countryOfDomicile: payment.countryOfDomicile,
              operation: payment.operation,
              promoCode: payment.promoCode,
            };
            paymentsFormatted.push(paymentObject);
          }
          this.rows = paymentsFormatted;
          this.fullData = result.userRows;
          this.total = result.resultsCount;
        })
        .catch((err) => {
          if (err == 401) {
            this.logout();
          }
        });
    },

    setFilters(data) {
      data.fromDate = this.fromDate;
      data.toDate = this.toDate;
      if (data.sortBy.column === "id") {
        data.sortBy = {
          column: "operationDate",
          order: "desc",
        };
      }
      if (this.countryOfDomicile === "") {
        data.countryId = null;
      } else {
        data.countryId = this.getCountryId(this.countryOfDomicile);
      }
      if (this.operation === "") {
        data.operation = null;
      } else {
        data.operation = this.operation;
      }
    },

    getCountryId(country) {
      const countryIds = {
        CH: 216,
        DE: 83,
        AT: 15,
        GB: 235,
        NL: 157,
        ES: 209,
      };
      return countryIds[country];
    },

    startOfMonth() {
      const date = new Date();
      date.setDate(1);
      return date.toISOString().split("T")[0];
    },

    // today() {
    //   return new Date().toISOString().split("T")[0];
    // },
    
    // Let's return today + 1, Atanas was busy so I coded it here in FE:
    today() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    },

    convertToPeriod($billingPeriod) {
      if ($billingPeriod === 0) return "Monthly";
      else if ($billingPeriod === 1) return "Annualy";
    },

    convertToPlan($plan) {
      if ($plan === 0) return "Explore";
      else if ($plan === 1) return "Learn";
      else if ($plan === 2) return "Grow";
    },

    formatDecimals(value) {
      let rounded = Math.round(value * 20) / 20;
      let formatted = rounded.toFixed(2);
      return formatted;
    },

    exportExcel: async function () {
      let data = {
        fromDate: this.fromDate,
        toDate: this.toDate,
        countryId: this.countryOfDomicile
          ? this.getCountryId(this.countryOfDomicile)
          : null,
        operation: this.operation ? this.operation : null,
        itemsPerPage: 1000000,
      };

      let dataToExport = null;

      try {
        const res = await this.getSubscribersPayments(data);
        dataToExport = res.userRows;
      } catch (err) {
        if (err === 401) {
          this.logout();
          return;
        }
        console.error(err);
        return;
      }

      // Create Excel data
      const excelData = dataToExport.map((row) => {
        return {
          "Subscription ID": row.subscriptionId,
          "First Name": row.firstName,
          "Last Name": row.lastName,
          Email: row.email,
          "Country of Domicile": row.countryOfDomicile,
          "Paid Plan": this.convertToPlan(row.paidPlan),
          Operation: row.operation,
          "Operation Date": row.operationDate,
          "Currency Code": row.currencyCode,
          "Amount Ex VAT": row.amountExVAT,
          "Amount Inc VAT": row.amountIncVAT,
          "Amount VAT": row.amountVAT,
          "VAT Rate": row.vatRate,
          "Billing Period": this.convertToPeriod(row.billingPeriod),
          "Card Brand": row.cardBrand,
          "Card Expiry Date": row.cardExpiryDate,
          "Card Number": row.cardNumber,
          "Payment Log ID": row.paymentLogId,
          "Payrexx Fee": row.payrexxFee,
        };
      });

      // Create a worksheet
      const ws = utils.json_to_sheet(excelData);

      // Create a new workbook and add the worksheet to it
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      // Format the date for the file name
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const fileName = `Subscribers_Payments_${formattedDate}.xlsx`;

      // Write the workbook to a file
      writeFile(wb, fileName);
    },
  },
};
</script>

<style scoped>
.field {
  margin: 15px 0 30px;
  display: flex;
  align-items: center;
}

.date-filter {
  display: flex;
  align-items: center;
}

.field label:not(.custom-check) {
  color: #44444f;
  font-size: 16px;
  font-weight: 500;
  width: 100px;
}

.field input,
.field :deep(input) {
  width: 200px;
  height: auto;
  box-shadow: none;
  line-height: initial;
  background: #fafafb;
  border: 1px solid #44444f;
  border-radius: 10px;
  padding: 12px 15px;
  margin: 0 10px;
  outline: none;
  font-size: 16px;
  color: #171725;
}

.field input:focus,
.field :deep(input:focus) {
  border-color: #3c6e71;
}

.field button {
  background: #3c6e71;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  width: 100px;
  height: 51px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
}

.field button:hover {
  background: #fff;
  color: #3c6e71;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.export-btn {
  background: #3c6e71;
  color: #fff;
  border: none;
  font-weight: 600;
  height: 40px;
  width: 200px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: block;
  margin: 0 0 25px auto;
}

.export-btn:hover {
  background: #fff;
  color: #3c6e71;
}

.country-filter {
  display: flex;
  align-items: center;
}

.country-filter label {
  font-size: 18px;
  margin-right: 10px;
}

.country-filter select {
  width: 210px;
  border: none;
  border-radius: 20px;
  padding: 9px 15px;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /*background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 0.75rem center/8px 10px;*/
  background-color: white;
}

.country-filter .filter-btn {
  background: #3c6e71;
  color: #fff;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.country-filter .filter-btn:hover {
  background: #fff;
  color: #3c6e71;
}
</style>

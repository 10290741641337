<template>
  <div class="notification-editor">
    <div class="edit-mode-notice" v-if="notificationTypeId != 67">
      <i class="fas fa-edit"></i> You are in EDIT MODE
    </div>

    <div class="common-options" v-if="notificationTypeId != 67">
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="selectIsActive">
          <span class="checkmark"></span>
          Mark as Active
        </label>
      </div>
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isTested">
          <span class="checkmark"></span>
          Mark as Tested
        </label>
      </div>
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="notificationData.isPush">
          <span class="checkmark"></span>
          Enable Push Notification
        </label>
      </div>
      <div class="field">
        <label class="expiration-date">Expiration Date</label>
        <Datepicker class="datepicker" v-model="notificationData.expirationDate" format="yyyy-MM-dd HH:mm:ss" :enable-time-picker="true" />
      </div>
      <div class="field">
        <label class="description">Trigger:</label>
        <input type="text" v-model="description" />
      </div>
      <div class="field">
        <label class="receivers">Receivers:</label>
        <input type="text" v-model="receivers" />
      </div>
      <div class="field category-dropdown">
        <label class="category-dropdown">Category:</label>
        <select v-model="selectCategory">
          <option v-for="(categoryName, categoryId) in categories" :key="categoryId" :value="categoryId">{{ categoryName }}</option>
        </select>
      </div>
    </div>

    <div class="edit-content">
      <div class="editor-items">
        <div class="add-item-container">
          <div class="items-header">NOTIFICATION TEXT</div>
          <select v-if="showItemOptions" @change="addItem($event)" v-model="newItemType">
            <option value="" disabled>Select Item Type</option>
            <option value="text">Text</option>
            <option value="object" v-if="notificationTypeId != 67">Object</option>
            <option value="link">Link</option>
            <option value="url">External Link</option>
          </select>
          <button class="add-item" @click="showItemOptions = !showItemOptions">+</button>
        </div>
        <draggable v-model="editableItems" item-key="id" handle=".handle">
          <template v-slot:item="{ element, index }">
            <div :key="index" class="draggable-item">
              <span class="handle">☰</span>
              <span v-if="element.type == 'object'" class="object-indicator">{{ getObjectString() }}</span>
              <input v-else-if="element.type == 'text'" v-model="element.text" type="text" placeholder="Text">
              <div v-else-if="element.type == 'link' || element.type == 'url'" class="input-links">
                <input class="link-title" v-model="element.title" type="text" placeholder="Link Title">
                <div class="route-tooltip">
                  <input v-if="element.type == 'link'" v-model="element.route" type="text" placeholder="Link Route">
                  <input v-else-if="element.type == 'url'" v-model="element.link" type="text" placeholder="External Url">
                  <div class="real-route">{{ getRealRoute(element) }}</div>
                </div>
              </div>
              <button class="remove-item" @click="removeItem(index)">-</button>
            </div>
          </template>
        </draggable>
      </div>
      <div class="editor-actions" v-if="notificationTypeId != 67">
        <div class="actions-header">NOTIFICATION ACTIONS</div>
        <div class="add-action-container">
          <button class="add-action" @click="addAction()">+</button>
        </div>
        <div class="action-inputs" v-for="(action, actionIndex) in editableActions" :key="actionIndex">
          <input class="action-title" v-model="action.title" type="text" placeholder="Action Title">
          <select class="action-type" v-model="action.type">
            <option value="" disabled>Select Action Type</option>
            <option value="accept">Accept</option>
            <option value="reject">Reject</option>
          </select>
          <button class="remove-action" @click="removeAction(actionIndex)">-</button>
        </div>
      </div>
      <div class="editor-buttons" v-if="notificationTypeId != 67">
        <button class="save-button" @click="saveChanges" :disabled="isSaving">
          {{ isSaving ? 'Saving...' : 'Save' }}
        </button>        
        <button class="reset-button" @click="resetToOriginal()" :disabled="isSaving">Reset</button>
      </div>
      <p v-if="successMsg" class="success-message">{{successMsg}}</p>
      <p v-if="errorMsg" class="error-message">{{errorMsg}}</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import notificationMixin from "@/mixins/notification";
import dayjs from 'dayjs';

export default {
  name: "NotificationEditor",
  mixins: [notificationMixin],
  components: {
    draggable,
  },
  props: {
    notificationTypeId: Number,
    data: Object,
    contentData: Object,
    language: String
  },
  data() {
    return {
      notificationData: this.data,
      originalContentData: JSON.parse(JSON.stringify(this.contentData || [])), // deep copy of contentData
      editableItems: this.contentData[this.language]?.items || [],
      editableActions: this.contentData[this.language]?.actions || [],
      newItemType: '',
      showItemOptions: false,
      successMsg: '',
      errorMsg: '',
      availableLanguages: {'en': 'English', 'de': 'German'},
      isSaving: false,
      isTested: false,
      isActive: this.data.status,
      description: this.data.description,
      receivers: this.data.receivers,
      category: this.data.category,
      categories: {
        0: 'System',
        1: 'Portfolio',
        2: 'Contest',
        3: 'Transaction',
        4: 'Permission',
        5: 'Interaction',
        6: 'Education'
      },
    };
  },
  methods: {
    addItem(event) {
      const type = event.target.value;
      let newItem;

      if (type === 'text') {
        newItem = { type: 'text', text: '' };
      } else if (type === 'object') {
        newItem = { type: 'object', object: {} };
      } else if (type === 'link') {
        newItem = { type: 'link', title: '', route: '' };
      } else if (type === 'url') {
        newItem = { type: 'url', title: '', link: '' };
      }

      if (newItem) {
        this.editableItems.push(newItem);
        this.handleUpdateItems(this.editableItems, this.language);
        this.showItemOptions = false;
      }
      
      this.newItemType = '';
    },
    resetToOriginal() {
      const originalItems = JSON.parse(JSON.stringify(this.originalContentData[this.language]?.items ?? []));
      const originalActions = JSON.parse(JSON.stringify(this.originalContentData[this.language]?.actions ?? []));
      this.editableItems = originalItems;
      this.editableActions = originalActions;
      this.handleUpdateItems(originalItems, this.language);
      this.handleUpdateActions(originalActions, this.language);
    },
    removeItem(index) {
      this.editableItems.splice(index, 1);
      this.handleUpdateItems(this.editableItems, this.language);
    },
    handleUpdateItems(items, language) {
      let data = { items: items, language: language };
      this.$emit('update-items', data);
    },
    handleUpdateActions(actions, language) {
      let data = { actions: actions, language: language };
      this.$emit('update-actions', data);
    },
    addAction() {
      this.editableActions.push({ title: '', type: {} });
      this.handleUpdateActions(this.editableActions, this.language);
    },
    removeAction(actionIndex) {
      this.editableActions.splice(actionIndex, 1);
      this.handleUpdateActions(this.editableActions, this.language);
    },
    getObjectString() {
      return '{{Object}}';
    },
    getRealRoute(item) {
      if (item.type && item.type === 'url') {
        return item.link;
      }

      const siteUrl = 'https://umushroom.com/' + this.language + '/';
      return siteUrl + item.route;
    },
    saveChanges() {
      if (this.isSaving) {
        return;
      }

      const validateItems = this.validateItems();
      const validateActions = this.validateActions();

      if (!validateItems || !validateActions) {
        setTimeout(() => {
          this.errorMsg = '';
        }, 4000);

        return;
      }

      if (confirm('Are you sure you want to save the changes? This action cannot be undone. Please preview the message below before you click "OK"')) {
        this.isSaving = true;
        let expirationDate = null;
        if (this.notificationData.expirationDate) {
          expirationDate = dayjs(this.notificationData.expirationDate).format('YYYY-MM-DD HH:mm:ss');
        }

        const dataToSend = {
          isTested: this.isTested,
          isPush: this.notificationData.isPush,
          expirationDate: expirationDate,
          contentData: this.contentData
        };

        if (this.selectIsActive !== this.data.status) {
          dataToSend.status = this.selectIsActive;
        }
        if (this.selectCategory !== this.data.category) {
          dataToSend.category = parseInt(this.selectCategory);
        }
        if (this.description !== this.data.description) {
          dataToSend.description = this.description;
        }
        if (this.receivers !== this.data.receivers) {
          dataToSend.receivers = this.receivers;
        }

        this.updateNotificationType(this.notificationTypeId, dataToSend)
          .then((response) => {
            if (response && response.status === true) {
              this.originalContentData = JSON.parse(JSON.stringify(this.contentData || []));
              this.successMsg = 'English and German TAB changes saved successfully!';
              setTimeout(() => {
                this.successMsg = '';
              }, 5000);
            }
          })
          .catch((error) => {
            console.error('Update failed', error);
            this.errorMsg = 'Failed to save changes. Please try again.';
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.isSaving = false;
      }
    },
    validateItems() {
      let isValid = true;

      for (let languageKey in this.availableLanguages) {
        let language = this.availableLanguages[languageKey];
        if (this.contentData[languageKey] && this.contentData[languageKey].items) {
          this.contentData[languageKey].items.forEach(item => {
            if (item.type === 'text') {
              let itemText = typeof item.text === 'string' ? !!item.text.trim() : false;

              if (!itemText) {
                this.errorMsg = 'Please fill Text item in ' + language + ' TAB';
                isValid = false;
                return;
              }
            } else if (item.type === 'link') {
              let linkTitle = typeof item.title === 'string' ? !!item.title.trim() : false;
              let linkRoute = typeof item.route === 'string' ? !!item.route.trim() : false;

              if (!linkTitle) {
                this.errorMsg = 'Please fill Link Title in ' + language + ' TAB';
                isValid = false;
                return;
              }

              if (!linkRoute) {
                this.errorMsg = 'Please fill Link Route in ' + language + ' TAB';
                isValid = false;
                return;
              }
            } else if (item.type === 'url') {
              let linkTitle = typeof item.title === 'string' ? !!item.title.trim() : false;
              let link = typeof item.link === 'string' ? !!item.link.trim() : false;

              if (!linkTitle) {
                this.errorMsg = 'Please fill Link Title in ' + language + ' TAB';
                isValid = false;
                return;
              }

              if (!link) {
                this.errorMsg = 'Please fill Url in ' + language + ' TAB';
                isValid = false;
                return;
              }
            }
          });
        }
      }

      return isValid;
    },
    validateActions() {
      let isValid = true;

      for (let languageKey in this.availableLanguages) {
        let language = this.availableLanguages[languageKey];
        if (this.contentData[languageKey] && this.contentData[languageKey].actions) {
          this.contentData[languageKey].actions.forEach(action => {
            let actionTitle = typeof action.title === 'string' ? !!action.title.trim() : false;
            let actionType = typeof action.type === 'string' ? !!action.type.trim() : false;

            if (!actionTitle) {
              this.errorMsg = 'Please fill action Title in ' + language + ' TAB' ;
              isValid = false;
              return;
            }

            if (!actionType) {
              this.errorMsg = 'Please fill action Type in ' + language + ' TAB' ;
              isValid = false;
              return;
            }
          });
        }
      }

      return isValid;
    },
  },
  watch: {
    editableItems(newValue) {
      this.handleUpdateItems(newValue, this.language);
    },
    editableActions(newValue) {
      this.handleUpdateActions(newValue, this.language);
    },
    language(newLanguage) {
      this.editableItems = this.contentData[newLanguage]?.items || [];
      this.editableActions = this.contentData[newLanguage]?.actions || [];
    }
  },

  computed: {
    selectIsActive: {
      get() {
        return this.isActive;
      },
      set(value) {
        this.isActive = value;
      }
    },
    selectCategory: {
      get() {
        return this.category;
      },
      set(value) {
        this.category = value;
      }
    },
  },

};
</script>

<style scoped>
select {
  padding: 3px 12px;
  color: #696974;
  font-weight: 500;
  border: 1px solid #e2e2ea;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.notification-editor {
  margin: 3% 0;
  border: 2px solid black;
}

.draggable-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.draggable-item .handle {
  font-size: 20px;
  font-weight: 800;
}

.handle {
  cursor: move;
  margin-right: 10px;
}

.remove-item, .remove-action {
  margin-left: 10px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
}

.notification-editor .edit-mode-notice {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #FD7E14;
  background-color: #FFF3E0;
  border: 1px solid #FD7E14;
  margin: 0;
}

.notification-editor .edit-mode-notice i {
  margin-right: 5px;
}

.edit-content {
  padding: 2% 5%;
}

.editor-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 4%;
}

.save-button {
  background-color: var(--dark-green);
}

.reset-button {
  background-color: #a75353;
}

.save-button, .reset-button {
    padding: 10px 20px;
    color: white;
    border-radius: 10px;
    border: 0;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}

.add-item, .add-action {
  margin-left: 10px;
  cursor: pointer;
  background-color: var(--dark-green);
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}

.add-item-container, .add-action-container {
  text-align: right;
  margin: 10px 0px;
}

.draggable-item input, .editor-actions input {
  border: 1px solid #e2e2ea;
  background: #fff;
  border-radius: 10px;
  width: 60%;
  height: 50px;
  padding: 0 8px;
  font-size: 16px;
}

.action-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.action-inputs {
  width: 60%;
}

.action-title {
  width: 53%;
  margin-right: 2%;
  display: inline-block;
}

.action-type {
  display: inline-block;
  width: 45%;
  height: 50px;
  padding: 0 8px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  background: #fff;
  font-size: 14px;
}

.action-type option {
  font-size: 14px;
}

.input-links {
  display: inline-block;
  width: 60%;
}

.input-links .link-title {
  width: 53%;
  margin-right: 2%;
}

.route-tooltip {
  position: relative;
  display: inline-block;
  width: 45%;
}

.route-tooltip input {
  width: 100%;
}

.route-tooltip:hover .real-route {
  display: block;
}

.real-route {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
  bottom: 125%;
  left: 0;
  white-space: nowrap;
}

.actions-header, .items-header {
  text-align: center;
  font-weight: 800;
  border-bottom: 2px solid gray;
  margin: 2% 0px;
}

.actions-header {
  margin-top: 6%;
}

.common-options {
  padding: 2% 5%;
}

.datepicker input {
  padding-left: 6% !important;
}

.category-dropdown select {
  padding: 10px;
  color: #696974;
  font-weight: 500;
  border: 1px solid #e2e2ea;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  width: 100%;
  height: 50px;
  font-size: 16px;
}

.category-dropdown select:focus {
  outline: none;
  border-color: var(--dark-green);
}

.category-dropdown option {
  font-size: 16px;
}
</style>

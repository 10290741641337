<template>

  <div class="system-achievements">
    <h1>Filter Users By <small v-if="total">({{total}})</small></h1>

    <!--
    <textarea id="native-sql" v-model="nativeSql" placeholder="Enter native sql" style="width: 100%; min-height: 150px;"></textarea>
    -->

    <div class="field autocomplete" v-if="communityGroups">
      <label>Community Group (mandatory):</label>
      <vue3-simple-typeahead
              placeholder="Type community group..."
              :items="communityGroups"
              :minInputLength="1"
              :itemProjection="(item) => item.name"
              @selectItem="select"
              @onInput="keyUpEvent"
      ></vue3-simple-typeahead>
    </div>

    <div class="field">
      <label style="width: 300px;">User has shared portfolios in the CG:</label>
      <div
          class="custom-select"
          :class="{open: hasSharedPortfoliosSelect}"
          @click="hasSharedPortfoliosSelect = !hasSharedPortfoliosSelect"
          off_v-click-away="hasSharedPortfoliosSelect = false"
          style="width: 150px;"
      >
        <span v-if="filters.selectedHasSharedPortfolios">{{ filters.selectedHasSharedPortfolios }}</span>
        <span v-else>Select</span>
        <div class="select-dropdown" v-if="hasSharedPortfoliosSelect">
          <a @click="selectUserHasSharedPortfolios('yes')">
            yes
          </a>
          <a @click="selectUserHasSharedPortfolios('no')">
            no
          </a>
          <a @click="selectUserHasSharedPortfolios('all')">
            all
          </a>
        </div>
      </div>
    </div>



    <label>Achievements awarded:</label>
    <div class="achievements-checkboxes-wrapper">
      <label class="custom-check green" v-for="achievement in achievementsAll" :key="achievement.id">
          <input type="checkbox" v-model="achievement.selected">
          <span class="checkmark"></span>
          <img :src=achievement.image class="achievement-icon" /> <span style="position: relative; top: -20px;">{{achievement.name}}</span>
      </label>
    </div>


    <div class="field">
      <label>Portfolio tags (comma separated):</label>
      <input type="text" v-model="filters.selectedPortfolioTags" />
    </div>

    <button class="green-btn" @click="onRunItClickHandler()" :disabled="addLoading || !filters.selectedCommunityGroup || !filters.selectedHasSharedPortfolios">
      Run it
      <font-awesome-icon v-if="addLoading" icon="spinner" spin />
    </button>

    <button class="green-btn" @click="excelExportHandler()" style="margin-left: 10px;" :disabled="addLoading || !filters.selectedCommunityGroup || !filters.selectedHasSharedPortfolios">
      Excel Export
      <font-awesome-icon v-if="addLoading" icon="spinner" spin />
    </button>

      <div style="height: 30px;"></div>

    <!-- Table with results -->
    <DataTableHolder
            :columns="columns"
            :rows="rows"
            :totalPages="pages"
            :updateResults="updateResults"
            :actions="[]"
            @update-table="getUserByFiltersSet1Handler"
    />
  </div>

</template>

<script>
  import userMixin from '@/mixins/user'
  import usersMixin from '@/mixins/users'
  import achievementsMixin from '@/mixins/achievements'
  import marketingMixin from '@/mixins/marketing'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'FilterUsers',
    mixins: [userMixin, usersMixin, achievementsMixin, marketingMixin],
    components: {
      DataTableHolder
    }, // components
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'userId',
            sortable: true
          },
          {
            title: 'User Name',
            name: 'userName',
            sortable: true
          },
          {
            title: 'Email',
            name: 'userEmail',
            sortable: true
          },
          {
            title: 'Onboarding completed',
            name: 'onboardingCompleted',
            sortable: true
          },
          {
            title: 'Achivements Count',
            name: 'achievementsCount',
            sortable: true
          },
          //{
          //  title: 'Achievements',
          //  name: 'badgesData',
          //  sortable: true
          //},
          {
            title: 'Portfolios Count',
            name: 'portfoliosCount',
            sortable: true
          },
          {
            title: 'Portfolio Performance',
            name: 'portfolioPerformance',
            sortable: true
          },
        ],
        nativeSql: null,
        updateResults: false,
        addLoading: false,
        total: null,
        fullData: null,
        achievementsAll: [],
        communityGroups: null,
        hasSharedPortfoliosSelect: false,
        hasCurrencySelect: false,
        filters: {
          selectedCommunityGroup: null,
          selectedHasSharedPortfolios: 'all',
          selectedAwardedAchievements: null,
          selectedPortfolioTags: null,
          // selectedCurrency: null,
          // selectedCreatedDates: [],
          // selectedPerformanceDates: [],
        },
        snapshots: {
          dataTableFilters: {}
        }
      }
    }, // data()

    methods: {

      getAchievementsAllHandler(){
        this.getAchievements()
                .then(res => {
                  this.achievementsAll = res.items;
                })
                .catch(err => {
                  if (err == 401) {
                    this.logout();
                  }
                })
      },

      getCommunityGroupsHandler() {

        let dataToSend = {
          itemsPerPage: 10000,
          page: 1,
          sortBy: {
            column: 'id',
            order: 'ASC'
          }
        };

        this.getCommunityGroupsPaginated(dataToSend)
                .then(res => {
                  this.communityGroups = res.communityGroups;
                })
                .catch(err => {
                  if (err == 401) {
                    this.logout();
                  }
                })

      }, // function getCommunityGroupsHandler

      getUserByNativeSqlHandler(data){

          // console.log('===========');
          // console.log(data);

        this.rows = null;
        this.fullData = null;
        this.total = null;

        data.sql = this.nativeSql;
        this.getUsersByNativeSql(data)
                .then(res => {

                  let usersFormatted = [];

                  for (const item of res.items) {
                    let singleUserFormatted = {
                      userId: item.id,
                      userName: item.firstName + ' ' + item.lastName,
                      userEmail: item.email,
                      onboardingCompleted: 'onb compl',
                      achievementsCount: item.achievementsCount,
                      //badgesData: JSON.parse(item.userbadgesdata)['achieved'],
                      portfoliosCount: item.portfoliosCount
                    };

                    usersFormatted.push(singleUserFormatted);
                  }

                  this.rows = usersFormatted;
                  this.fullData = res.items;
                  this.total = res.itemsCount;
                })
                .catch(err => {
                  if (err == 401) {
                    this.logout();
                  }
                })

      }, // getUserByNativeSqlHandler


        getUserByFiltersSet1Handler(data){

          if (this.filters.selectedCommunityGroup && this.filters.selectedHasSharedPortfolios) {
            data.communityGroupId = this.filters.selectedCommunityGroup.id;
            data.hasSharedPortfolios = this.filters.selectedHasSharedPortfolios;
          }

          //this.rows = null;
          //this.fullData = null;
          //this.total = null;

          data.achievementsIds = this.getSelectedAwardedAchievementsIds();
          data.portfolioTags = this.getSelectedPortfoliosTagsAsArray();
          // data.currency = this.filters.selectedCurrency;
          // data.createdFrom = this.formatDateDashes(this.filters.selectedCreatedDates[0]);
          // data.createdTo = this.formatDateDashes(this.filters.selectedCreatedDates[1]);
          // data.performanceFrom = this.formatDateDashes(this.filters.selectedPerformanceDates[0]);
          // data.performanceTo = this.formatDateDashes(this.filters.selectedPerformanceDates[1]);

          this.snapshots.dataTableFilters = data;

          this.addLoading = true;
          this.getUsersByFiltersSet1(data)
              .then(res => {

                  let usersFormatted = [];

                  for (const item of res.items) {
                      let singleUserFormatted = {
                          userId: item.id,
                          userName: item.firstName + ' ' + item.lastName,
                          userEmail: item.email,
                          onboardingCompleted: item.onboardingCompleted,
                          achievementsCount: item.achievementsCount,
                          //badgesData: JSON.parse(item.userbadgesdata)['achieved'],
                          portfoliosCount: item.portfoliosCount,
                          portfolioPerformance: item.portfolioPerformance
                      };

                      usersFormatted.push(singleUserFormatted);
                  }

                  this.rows = usersFormatted;
                  this.fullData = res.items;
                  this.total = res.itemsCount;
                  this.addLoading = false;
              })
              .catch(err => {
                  if (err == 401) {
                      this.logout();
                  }
              })

        }, // getUserByFiltersSet1Handler

      getAchievementById(achievementId) {
        let result = this.achievementsAll.filter(function(item){
          return item.id == achievementId;
        });

        return result[0];
      }, // function getAchievementById

      getAchievementsFormatted(user) {
        let result = '';

        if (user.badgesData && user.badgesData.achieved) {
          let indexUserAchievement = 0;
          for (const userAchievement of user.badgesData.achieved) {
            let fullUserAchievement = this.getAchievementById(userAchievement.id);
            //console.log(fullUserAchievement.translation.en.name);

            if (fullUserAchievement) {
              result = result + (indexUserAchievement != 0 ? ', ' : '') + fullUserAchievement.translation.en.name;
              //result = result + (indexUserAchievement != 0 ? ', ' : '') + userAchievement.id;
            }
            indexUserAchievement++;
          }
        }

        return result;
      }, // function getAchievementsFormatted


        getSelectedAwardedAchievementsIds(){
            let selectedAchievementIds = [];
            let achievementsAll = this.achievementsAll;

            /*
            if (this.filters.selectedAwardedAchievements && this.filters.selectedAwardedAchievements.length > 0) {

              let selectedAchievementKeys = this.filters.selectedAwardedAchievements.split(',').map(function(item){
                return item.trim();
              });

              for (const achievementKey of selectedAchievementKeys) {
                for (const achievementFull of achievementsAll) {
                  if (achievementFull.name == achievementKey) {
                    selectedAchievementIds.push(achievementFull.id);
                  }
                }
              }
            }
            */

            for (const currentAchievement of achievementsAll) {
                if (currentAchievement.selected == true) {
                    selectedAchievementIds.push(currentAchievement.id);
                }
            }

          return selectedAchievementIds;
        }, // function getSelectedAwardedAchievementsIds


        getSelectedPortfoliosTagsAsArray(){
          let result = [];

            if (this.filters.selectedPortfolioTags && this.filters.selectedPortfolioTags.length > 0) {
                result = this.filters.selectedPortfolioTags.split(',').map(function(item){
                    return item.trim();
                });
            }

            return result;
        },


      select(cg) {
        this.filters.selectedCommunityGroup = null;
        setTimeout(() => {
          this.filters.selectedCommunityGroup = cg;
        }, 100);
      },
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.filters.selectedCommunityGroup = null;
        }
      },

      selectUserHasSharedPortfolios(optionValue) {
        this.filters.selectedHasSharedPortfolios = optionValue;
      },

      selectUserPortfolioHasCurrency(optionValue) {
        this.filters.selectedCurrency = optionValue;
      },

      onRunItClickHandler() {
        //console.log('hello 335');
        //console.log(this.filters);
        //this.getUserByNativeSqlHandler({});

          //console.log(this.achievementsAll);

        this.getUserByFiltersSet1Handler({});
      },

      formatDateDashes(dateString) {
        const date = new Date(dateString); // Parse the date string
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed

        return `${year}-${month}-${day}`;
      },

      excelExportHandler() {

          if (this.filters.selectedCommunityGroup && this.filters.selectedHasSharedPortfolios) {
              let dataToSend = {
                  communityGroupId: this.filters.selectedCommunityGroup.id,
                  hasSharedPortfolios: this.filters.selectedHasSharedPortfolios,
                  achievementsIds: this.getSelectedAwardedAchievementsIds(),
                  portfolioTags: this.getSelectedPortfoliosTagsAsArray(),
                  excelExportMode: true,
                  sortBy: this.snapshots.dataTableFilters.sortBy,
                  // currency: this.filters.selectedCurrency,
                  // createdFrom: this.formatDateDashes(this.filters.selectedCreatedDates[0]),
                  // createdTo: this.formatDateDashes(this.filters.selectedCreatedDates[1]),
                  // performanceFrom: this.formatDateDashes(this.filters.selectedPerformanceDates[0]),
                  // performanceTo: this.formatDateDashes(this.filters.selectedPerformanceDates[1]),
              };

              this.addLoading = true;
              this.getUsersByFiltersSet1ExcelExport(dataToSend)
                  .then(response => {
                      // create file link in browser's memory
                      const href = URL.createObjectURL(response);

                      // create "a" HTML element with href to file & click
                      const link = document.createElement('a');
                      link.href = href;
                      link.setAttribute('download', `Users-Report.xlsx`); //or any other extension
                      document.body.appendChild(link);
                      link.click();

                      // clean up "a" element & remove ObjectURL
                      //document.body.removeChild(link);
                      URL.revokeObjectURL(href);
                      this.addLoading = false;
                  })
          }
      } // function excelExportHandler

    },

    created() {
      this.getAchievementsAllHandler();
      this.getCommunityGroupsHandler();
    },


  } // export default

</script>


<style scoped>
.field {
    margin: 15px 0;
    display: flex;
    align-items: center;
  }

  .field label:not(.custom-check) {
    color: #44444f;
    font-size: 16px;
    font-weight: 500;
  }

  .field input,
  .field :deep(input) {
    width: 400px;
    height: auto;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #44444f;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .field input:focus,
  .field :deep(input:focus) {
    border-color: #3C6E71;
  }

  .field button {
    background: #3c6e71;
    color: #FFF;
    font-weight: 500;
    font-size: 18px;
    width: 100px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
  }

  .field button:hover {
    background: #FFF;
    color: #3c6e71;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  .custom-select {
    width: 100%;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0;
    outline: none;
    font-size: 16px;
    color: #171725;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .custom-select.open {
    border-color: #3c6e71;
  }

  .custom-select:after {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #B5B5BE;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    right: 20px;
  }

  .custom-select .select-dropdowns {
    background: #FAFAFB;
  }

  img.achievement-icon {
    width: 50px;
  }

  .custom-check.green .checkmark {
    top: 15px;
  }

  .achievements-checkboxes-wrapper {
    display: flex;

    margin-top: 15px;
    height: 340px;

    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

</style>
